.grow {
    animation: grow 1s 1 ease-in-out;
}

@keyframes grow {
    from {
        transform: scale(0.05);
    }
    to {
        transform: scale(1);
    }
}

.hide {
    animation: hide 0.3s 1 ease-in-out;
    animation-fill-mode: both;
}

@keyframes hide {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.05);
        opacity: 0;
    }
}
.flash {
    animation: flash 1s infinite ease-in-out;
}
@keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
}
.flash-slow {
    animation: flash-slow 1s infinite ease-in-out;
}
@keyframes flash-slow {
    from,
    to {
      opacity: 0;
    }

    20%,
    80% {
      opacity: 1;
    }
}

.shake {
    animation: shake 1s 1 ease-in-out;
}

@keyframes shake {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }

  .attention {
    left: 40px !important;
    top: 40px !important;
    animation: attention 1s 1 ease-in-out;
    z-index: 99;
  }

  @keyframes attention {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1,1,1);
    }
  }
