@import 'animations';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&display=swap');
* {
    font-family: 'Oswald', sans-serif;
    // transition: all 0.1s;
    margin: 0;
    padding: 0;
    transform-style: preserve-3d;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
html, body {
    height: 100%;
    overflow: hidden;
    cursor: url('/assets/imgs/crusor.png'), auto;
    background-color: black;
}

// @font-face {
//     font-family: Janda;
//     src: url(./assets/JandaManateeSolid.ttf);
// }
.challenge-logo{
    // position: absolute;
    width: 397px;
    height: 208px;
    margin-bottom: 30px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('assets/imgs/challenge-logo.png');
}
.sponsered-logo{
    position: absolute;
    width: 328px;
    height: 115px;
    bottom: 100px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('assets/imgs/sponsered-logo.png');
}
#preloader {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 1);
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 2s;
}
#preloader-bar {
    /* position: absolute; */
    width: 20%;
    height: 20px;
    border-radius: 10px;
    background-color: white;
}
#bar-indicator {
    position: absolute;
    top: 3px;
    left: 1%;
    width: 1%;
    height: 14px;
    border-radius: 10px;
    background-color: red;
    transition: all 0.5s;
}

#container {
    /* width: 100vw;
    height: 100vh; */
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    perspective: 300px;
    overflow: hidden;
    z-index: 1;
    // display: none;
}

.questions-container {
    width: 70%;
    height: 30%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
}

.parallax-layer {
    position: absolute;
    top: 0;
    height: 100%;
    left: -10%;
    right: -10%;
    // background-color: yellowgreen;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &.level-0 {
        background-image: url('assets/imgs/parallax-level-0.png');
    }
    &.level-1 {
        background-image: url('assets/imgs/parallax-level-1.png');
    }
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(circle, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 90%);
}

app-player {
    position: absolute;
    width: 264px;
    height: 272px;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-color: red;
    color: yellow;
    transition: all 1s ease-in-out;
    &:nth-of-type(1) {
        top: 20px;
        left: 20px;
        background-image: url('/assets/imgs/player-01-bg.png');
    }
    &:nth-of-type(2) {
        top: 20px;
        left: calc(100vw - 290px);
        background-image: url('/assets/imgs/player-02-bg.png');
    }
    &:nth-of-type(3) {
        left: 20px;
        top: calc(100vh - 320px);
        background-image: url('/assets/imgs/player-03-bg.png');
    }
    &:nth-of-type(4) {
        top: calc(100vh - 320px);
        left: calc(100vw - 290px);
        background-image: url('/assets/imgs/player-04-bg.png');
    }
    &:nth-of-type(5) {
        top: 20px;
        left: calc(50vw - 132px);
        background-image: url('/assets/imgs/player-05-bg.png');
    }
    &.selected {
        top: 20px;
        left: 12%;
    }
    &.not_active {
        filter: grayscale(80%) brightness(50%);
        opacity: 0;
    }
    // transform: scale(0.7);
}

.question-symbol {
    width: 80px;
    height: 80px;
    margin: 5px;
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.5);
    &.score-500 {
        background-image: url('/assets/imgs/question-500.png');
    }
    &.score-300 {
        background-image: url('/assets/imgs/question-300.png');
    }
    &.score-100 {
        background-image: url('/assets/imgs/question-100.png');
    }
    text-shadow: 1px 1px 3px rgba($color: white, $alpha: 1);
    span {
        font-weight: 600;
        font-size: 20pt;
    }
    &[status="inactive"] {
        background-image: url('/assets/imgs/question-inactive.png');
        color: lightgray;
        text-shadow: none;
    }
}

app-question {
    position: absolute;
    width: 1200px;
    height: 556px;
    // left: 40%;
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // background-image: url('/assets/imgs/question-bg.png');
    &>div {
        width:100%;
        height:100%;
    }

}

.info {
    position: absolute;
    bottom: 140px;
    left: 40%;
    width: 20%;
    // height: 45px;
    font-size: 14pt;
    line-height: 40px;
    border: solid 1px white;
    border-radius: 20px;
    text-align: center;
    color: white;
    background-color: rgba($color: #000000, $alpha: 0.5);
}
.questions-key {
    position: absolute;
    bottom: 40px;
    width: 300px;
    height: 81px;
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('/assets/imgs/questions-key.png');
}
